<template>
  <!-- 账本信息 -->
  <div class="detail-info"
       v-if="ledgerData !== []">
    <div class="content"
         v-for="(item, index) in ledgerData"
         :key="index">

      <el-row>
          <el-col class="div-column details-div"
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="12"
                  :xl="12">
            <div class="div-row details-list">
              <div class="label-title">分片名</div>
              <div class="details-content">{{item.shardName}}</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">账本类型</div>
              <div class="details-content">{{item.ledgerCodeName}}</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">区块高度</div>
              <div class="details-content">{{toThousands(item.blockHeight)}}</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">哈希算法</div>
              <div class="details-content"
                   v-if="item.hashAlgorithm===201">sha3</div>
              <div class="details-content"
                   v-else-if="item.hashAlgorithm===202">sm3</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">账本哈希</div>
              <popover v-if="item.hash" :width="500" :shard="item.shardName" :value="item.hash" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
              <i v-if="item.hash"
                 class="el-icon-document-copy"
                 @click="copy($event, item.hash)"></i>
              <div v-else
                   class="details-content">无</div>
            </div>

            <div class="div-row details-list">
              <div class="label-title">创建者地址算法</div>
              <div class="details-content"
                   v-if="item.sendAlgorithm===101">rsa</div>
              <div class="details-content"
                   v-else-if="item.sendAlgorithm===102">sm2</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">创建者地址</div>
              <popover v-if="item.sendAddress" :width="500" :shard="item.shardName" :value="item.sendAddress" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
              <i v-if="item.sendAddress"
                 class="el-icon-document-copy"
                 @click="copy($event, item.sendAddress)"></i>
              <!-- <el-button v-if="item.sendAddress"
                         style="margin-left:16px;"
                         type="text"
                         icon="ym-icon-liulan"
                         @click="handleOpen(item.sendAddress, '创建者账户信息')"> 查看</el-button> -->
              <div v-else
                   class="details-content">无</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">验证者地址算法</div>
              <div class="details-content"
                   v-if="item.verifyAlgorithm===101">rsa</div>
              <div class="details-content"
                   v-else-if="item.verifyAlgorithm===102">sm2</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">验证者地址</div>
              <popover v-if="item.verifyAddress" :width="500" :shard="item.shardName" :value="item.verifyAddress" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
              <i v-if="item.verifyAddress"
                 class="el-icon-document-copy"
                 @click="copy($event, item.verifyAddress)"></i>
              <!-- <el-button v-if="item.verifyAddress"
                         style="margin-left:16px;"
                         type="text"
                         icon="ym-icon-liulan"
                         @click="handleOpen(item.verifyAddress, '验证者账户信息')"> 查看</el-button> -->
              <div v-else
                   class="details-content">无</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">创建地址签名</div>
              <popover v-if="item.sendSign" :showDict="false" :width="500" :shard="item.shardName" :value="item.sendSign" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
              <i v-if="item.sendSign"
                 class="el-icon-document-copy"
                 @click="copy($event, item.sendSign)"></i>
              <div v-else
                   class="details-content">无</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">验证地址签名</div>
              <popover v-if="item.verifySign" :showDict="false" :width="500" :shard="item.shardName" :value="item.verifySign" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
              <i v-if="item.verifySign"
                 class="el-icon-document-copy"
                 @click="copy($event, item.verifySign)"></i>
              <div v-else
                   class="details-content">无</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">备注</div>
          <popover v-if="item.remark" :width="500" :shard="item.shardName" :value="item.remark" :divClass="'shenglue'" :divStyle="[{'width': 300 + 'px'},{'color':'#3f536e'}]" :isSubStr="false"></popover>
              <div v-else
                   class="details-content">无</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">时间戳</div>
              <div class="details-content">{{tf(item.timestamp, 'YYYY-MM-DD HH:mm:ss')}}</div>
            </div>
          </el-col>
          <el-col class="div-column details-div"
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="12"
                  :xl="12">
            <div class="div-row details-list">
              <div class="label-title">执行结果</div>
               <div class="details-content"
                   v-if="item.errorCode=== '0' && item.result">成功</div>
              <div class="details-content"
                   v-else-if="item.errorCode=== '0' && !item.result">执行中</div>
              <div class="details-content"
                   v-else>失败</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">错误代码</div>
              <div class="details-content">{{item.errorCode}}</div>
            </div>
            <div class="div-row details-list">
              <div class="label-title">版本号</div>
              <div class="details-content">{{item.version}}</div>
            </div>
            <!-- 不同账本类型字段 -->
            <!-- 记账单位额外字段101 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 101">
              <div class="div-row details-list">
                <div class="label-title">接收者地址</div>
                <popover v-if="item.receiveAddress" :width="500" :shard="item.shardName" :value="item.receiveAddress" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.receiveAddress"
                   class="el-icon-document-copy"
                   @click="copy($event, item.receiveAddress)"></i>
                <!-- <el-button v-if="item.receiveAddress"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.receiveAddress, '接收者账户信息')"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">记账单位名称</div>
                <div class="details-content">{{item.name}}</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">记账单位标识符</div>
                <div class="details-content">{{item.unit}}</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">记账单位总数</div>
                <div class="details-content">{{toThousands(item.count)}}</div>
              </div>
            </div>
            <!-- 交易额外字段102 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 102">
              <div class="div-row details-list">
                <div class="label-title">接收者地址</div>
                <popover v-if="item.receiveAddress" :width="500" :shard="item.shardName" :value="item.receiveAddress" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.receiveAddress"
                   class="el-icon-document-copy"
                   @click="copy($event, item.receiveAddress)"></i>
                <!-- <el-button v-if="item.receiveAddress"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.receiveAddress, '接收者账户信息')"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">记账单位哈希</div>
                <popover v-if="item.token" :width="500" :shard="item.shardName" :value="item.token" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.token"
                   class="el-icon-document-copy"
                   @click="copy($event, item.token)"></i>
                <!-- <el-button v-if="item.token"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.token)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">记账单位数量</div>
                <div class="details-content">{{item.value}}</div>
              </div>
            </div>
            <!-- 存证账本额外字段103 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 103">
              <div class="div-row details-list">
                <div class="label-title">父存证账本哈希</div>
                <popover v-if="item.parentHash" :width="500" :shard="item.shardName" :value="item.parentHash" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.parentHash"
                   class="el-icon-document-copy"
                   @click="copy($event, item.parentHash)"></i>
                <!-- <el-button v-if="item.parentHash"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.parentHash, '父存证账本信息')"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">数据来源</div>
                <div class="details-content shenglue">{{item.dataSources || '无'}}</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">存证数据</div>
                <div class="details-content shenglue">{{item.message || '无'}}</div>
                <!-- <el-button v-if="item.message"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.message, '存证数据')"> 查看</el-button> -->
              </div>
            </div>
            <!-- 质押账本额外字段104 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 104">
              <div class="div-row details-list">
                <div class="label-title">接收者地址</div>
                <popover v-if="item.receiveAddress" :width="500" :shard="item.shardName" :value="item.receiveAddress" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.receiveAddress"
                   class="el-icon-document-copy"
                   @click="copy($event, item.receiveAddress)"></i>
                <!-- <el-button v-if="item.receiveAddress"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.receiveAddress, '接收者账户信息')"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">记账单位哈希</div>
                <popover v-if="item.token" :width="500" :shard="item.shardName" :value="item.token" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.token"
                   class="el-icon-document-copy"
                   @click="copy($event, item.token)"></i>
                <!-- <el-button v-if="item.token"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.token)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">赎回时间区间</div>
                <span class="details-content">{{getDay(item.redemptionTime)}}天</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">赎回时间</div>
                <span class="details-content">{{tf(item.redemptionTimestamp, 'YYYY-MM-DD HH:mm:ss') || '无'}}</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">质押数量</div>
                <span class="details-content">{{item.value || '无'}}</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">是否赎回</div>
                <span class="details-content"
                      v-if="item.recall">是</span>
                <span class="details-content"
                      v-else>否</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">赎回哈希</div>
                <popover v-if="item.reHash" :width="500" :shard="item.shardName" :value="item.reHash" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.reHash"
                   class="el-icon-document-copy"
                   @click="copy($event, item.reHash)"></i>
                <!-- <el-button v-if="item.reHash"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.reHash)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
            </div>
            <!-- 赎回账本额外字段105 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 105">
              <div class="div-row details-list">
                <div class="label-title">质押哈希</div>
                <popover v-if="item.depositHash" :width="500" :shard="item.shardName" :value="item.depositHash" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.depositHash"
                   class="el-icon-document-copy"
                   @click="copy($event, item.depositHash)"></i>
                <!-- <el-button v-if="item.depositHash"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.depositHash)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">接收者地址</div>
                <popover v-if="item.receiveAddress" :width="500" :shard="item.shardName" :value="item.receiveAddress" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.receiveAddress"
                   class="el-icon-document-copy"
                   @click="copy($event, item.receiveAddress)"></i>
                <!-- <el-button v-if="item.receiveAddress"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.receiveAddress, '接收者账户信息')"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">记账单位哈希</div>
                <popover v-if="item.token" :width="500" :shard="item.shardName" :value="item.token" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.token"
                   class="el-icon-document-copy"
                   @click="copy($event, item.token)"></i>
                <!-- <el-button v-if="item.token"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.token)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">赎回数量</div>
                <span class="details-content">{{item.value}}</span>
              </div>
            </div>
            <!-- 个性凭证额外字段106 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 106">
              <div class="div-row details-list">
                <div class="label-title">接收者地址</div>
                <popover v-if="item.receiveAddress" :width="500" :shard="item.shardName" :value="item.receiveAddress" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.receiveAddress"
                   class="el-icon-document-copy"
                   @click="copy($event, item.receiveAddress)"></i>
                <!-- <el-button v-if="item.receiveAddress"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.receiveAddress, '接收者账户信息')"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">个性凭证名称</div>
                <span class="details-content">{{item.name || '无'}}</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">个性凭证表示值</div>
                <span class="details-content">{{item.value}}</span>
              </div>
              <!-- <div class="div-row details-list">
                <div class="label-title">个性凭证哈希</div>
                <popover v-if="item.token" :width="500" :shard="item.shardName" :value="item.token" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.token"
                   class="el-icon-document-copy"
                   @click="copy($event, item.token)"></i>
                <div v-else
                     class="details-content">无</div>
              </div> -->
              <div class="div-row details-list">
                <div class="label-title">拥有者</div>
                <popover v-if="item.owner" :width="500" :shard="item.shardName" :value="item.owner" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.owner"
                   class="el-icon-document-copy"
                   @click="copy($event, item.owner)"></i>
                <!-- <el-button v-if="item.owner"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.owner, '拥有者账户信息')"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">最新流转记录</div>
                <popover v-if="item.txHash" :width="500" :shard="item.shardName" :value="item.txHash" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.txHash"
                   class="el-icon-document-copy"
                   @click="copy($event, item.txHash)"></i>
                <!-- <el-button v-if="item.txHash"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.txHash)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">是否销毁</div>
                <span class="details-content"
                      v-if="item.recall">是</span>
                <span class="details-content"
                      v-else>否</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">销毁记录</div>
                <popover v-if="item.daHash" :width="500" :shard="item.shardName" :value="item.daHash" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.daHash"
                   class="el-icon-document-copy"
                   @click="copy($event, item.daHash)"></i>
                <!-- <el-button v-if="item.daHash"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.daHash)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
            </div>
            <!-- 个性凭证交易额外字段107 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 107">
              <div class="div-row details-list">
                <div class="label-title">接收者地址</div>
                <popover v-if="item.receiveAddress" :width="500" :shard="item.shardName" :value="item.receiveAddress" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.receiveAddress"
                   class="el-icon-document-copy"
                   @click="copy($event, item.receiveAddress)"></i>
                <!-- <el-button v-if="item.receiveAddress"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.receiveAddress, '接收者账户信息')"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">个性凭证哈希</div>
                <popover v-if="item.token" :width="500" :shard="item.shardName" :value="item.token" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.token"
                   class="el-icon-document-copy"
                   @click="copy($event, item.token)"></i>
                <!-- <el-button v-if="item.token"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.token)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">上一笔交易记录</div>
                <popover v-if="item.parentHash" :width="500" :shard="item.shardName" :value="item.parentHash" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.parentHash"
                   class="el-icon-document-copy"
                   @click="copy($event, item.parentHash)"></i>
                <!-- <el-button v-if="item.parentHash"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.parentHash)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
            </div>
            <!-- 状态账本额外字段108 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 108">
              <div class="div-row details-list">
                <div class="label-title">状态索引</div>
                <popover v-if="item.statusKey" :showDict="false" :width="500" :shard="item.shardName" :value="item.statusKey" :style="[{'width': 310 + 'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.statusKey"
                   class="el-icon-document-copy"
                   @click="copy($event, item.statusKey)"></i>
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">状态数据</div>
                <!-- <div class="details-content shenglue">{{item.json || '无'}}</div> -->
                <popover v-if="item.json" :showDict="false" :showJson="true" :width="500" :shard="item.shardName" :value="item.json" :isSubStr='false' :divClass="'shenglue'" :style="[{'width': 310 + 'px'},{'color':'#3f536e'}]"></popover>
                <div v-else
                     class="details-content">无</div>
                <!-- <el-button v-if="item.json"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.json, '状态数据')"> 查看</el-button> -->
              </div>
              <div class="div-row details-list">
                <div class="label-title">版本号</div>
                <span class="details-content">{{item.statusVersion}}</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">上一次修改记录</div>
                <popover v-if="item.parentHash" :width="500" :shard="item.shardName" :value="item.parentHash" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.parentHash"
                   class="el-icon-document-copy"
                   @click="copy($event, item.parentHash)"></i>
                <!-- <el-button v-if="item.parentHash"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.parentHash)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
            </div>
            <!-- 合约额外字段201 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 201">
              <div class="div-row details-list">
                <div class="label-title">合约语言</div>
                <span class="details-content">{{item.languages}}</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">合约代码</div>
                <el-button v-if="item.contract"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.contract, '合约代码')"> 查看</el-button>
                <span class="details-content"
                      v-else>无</span>
              </div>
            </div>
            <!-- 个性凭证销毁额外字段1 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 1">
              <div class="div-row details-list">
                <div class="label-title">接收者地址</div>
                <popover v-if="item.receiveAddress" :width="500" :shard="item.shardName" :value="item.receiveAddress" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.receiveAddress"
                   class="el-icon-document-copy"
                   @click="copy($event, item.receiveAddress)"></i>
                <!-- <el-button v-if="item.receiveAddress"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.receiveAddress, '接收者账户信息')"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">个性凭证哈希</div>
                <popover v-if="item.token" :width="500" :shard="item.shardName" :value="item.token" :divStyle="[{'width':280+'px'},{'color':'#3f536e'}]" :num="32"></popover>
                <i v-if="item.token"
                   class="el-icon-document-copy"
                   @click="copy($event, item.token)"></i>
                <!-- <el-button v-if="item.token"
                           style="margin-left:16px;"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleDetail(item.token)"> 查看</el-button> -->
                <div v-else
                     class="details-content">无</div>
              </div>
            </div>
            <!-- 可编程账本额外字段301 -->
            <div class="div-column"
                 v-if="item.ledgerCode === 301">
              <div class="div-row details-list">
                <div class="label-title">可编程账本名称</div>
                <div class="details-content shenglue">{{item.name || '无'}}</div>
              </div>
            </div>
          </el-col>
        </el-row>

    </div>
    <!-- 账户弹窗 -->
    <!-- <el-dialog :title="title"
               width="60%"
               :visible.sync="showDialogAccount">
      <dialog-account :datas="accountForm"></dialog-account>
    </el-dialog> -->
    <!-- 其他内容弹窗 -->
    <!-- <el-dialog :title="title"
               width="55%"
               :visible.sync="showDialogData">
      <pre v-if="info"
           v-html="info"></pre>
      <div v-else>暂无数据</div>
    </el-dialog> -->
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import timeFormat from '@/utils/timeFormat.js'
import Popover from '@/components/Popover'
import publicFunction from '@/utils/publicFunction.js'
export default {
  name: 'dialogLedger',
  components: {
    Popover
  },
  data() {
    return {
      ledgerData: [],
      // 弹窗字段
      showDialogAccount: false, // 账户弹窗
      showDialogData: false, // 其他内容弹窗
      accountForm: '', // 弹窗内容
      title: '',
      info: ''
    }
  },
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('监听:', this.datas)
        // 清空数据
        this.ledgerData = []
        // 查询账本信息
        this.getData()
      },
      deep: false,
      immediate: false
    }
  },
  created() {},
  mounted() {
    // console.log('触发账本组件')
    // 查询账本信息
    this.getData()
  },
  methods: {
    // 数字加逗号转化为金额类型
    toThousands(num) {
      return publicFunction.toThousands(num)
    },
    // 查询账本信息
    async getData() {
      let options = {
        shard: this.datas.shard,
        hash: this.datas.hash
      }
      const { data } = await this.$api.chainQL.ledgerInfo(options)

      if (data.listLedger.ledger.length !== 0) {
        this.ledgerData = data.listLedger.ledger
      }
    },
    // 跳转详情
    // async handleDetail(value) {
    //   let options = {
    //     shard: this.datas.shard,
    //     hash: value
    //   }
    //   // 初始化数据
    //   this.ledgerData = []
    //   const { data } = await this.$api.chainQL.ledgerInfo(options)

    //   if (data.listLedger.ledger.length !== 0) {
    //     this.ledgerData = data.listLedger.ledger
    //     // console.log(this.ledgerData)
    //     this.$emit('ledger-say', 3)
    //   } else {
    //     // 显示空数据图
    //     this.$emit('ledger-say', 2)
    //   }
    // },
    // 查看详细信息
    // async handleOpen(value, type) {
    //   this.title = type
    //   let _type = type.substr(type.length - 4, type.length)
    //   if (_type === '账户信息') {
    //     this.showDialogAccount = true
    //     this.accountForm = {
    //       shard: this.datas.shard,
    //       address: value
    //     }
    //   } else if (
    //     type === '状态数据' ||
    //     type === '存证数据' ||
    //     type === '合约代码'
    //   ) {
    //     this.info = value
    //     this.showDialogData = true
    //   }
    // },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    // 毫秒转换成天
    getDay(time) {
      // console.log(time)
      let _time = time / 86400000
      // 向上取整
      return Math.ceil(_time)
    },
    // 一键复制
    copy(e, text) {
      if (this.copyLoading) {
        return
      }
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
      setTimeout(() => {
        this.copyLoading = false
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.dict-value {
  font-weight: bold;
  color: #3f536e;
  margin-bottom: 10px;
}

.detail-info {
  height: 65vh;
  background: #fff;
  margin-bottom: 12px;
  // border: 1px solid #cecece;
  .header {
    font-size: 16px;
    font-weight: 600;
    padding: 16px 20px 16px;
    border-bottom: 1px solid #cecece;
    background: #fff;
  }
  .content {
    margin-bottom: 12px;
    .div-column {
      display: flex;
      flex-direction: column;
    }
    .div-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .btn-left {
      margin-left: 16px;
    }
    .details-div {
     padding: 20px auto;
    }
    .details-list {
      line-height: 50px;
      color: #757575;
      // padding-left: 32px;
      .label-title {
        padding-right: 60px;
        width: 200px;
        text-align: right;
        font-weight: bold;
      }
      .details-content {
        // font-weight: bold;
        color: #3f536e;
        width: 260px;
        // background: chocolate;
      }
    }
    .data-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.nothing {
        padding-bottom: 20px;
        margin: 20px auto;
        font-size: 14px;
        color: #222b45;
        img {
          width: 104px;
          height: 105px;
        }
        .nothing-text {
          margin-left: -11px;
        }
      }
    }
  }
  // 不换行省略
  .shenglue {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  // js样式
  ::v-deep pre {
    min-height: 400px;
    background: rgb(241, 241, 241);
    padding: 10px;
    margin: 10px;
    font-size: 16px;
    overflow-x: scroll;
    .string {
      color: green;
    }
    .number {
      color: darkorange;
    }
    .boolean {
      color: blue;
    }
    .null {
      color: magenta;
    }
    .key {
      color: red;
    }
  }
  ::v-deep pre::-webkit-scrollbar {
    height: 10px;
  }
}
</style>
